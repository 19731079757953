import React, {ReactElement} from 'react';
import {StyleSheet, Text as NativeText, TextStyle} from 'react-native';
import {Component} from '@lib_components';
import {Colors, Spacing, Typography} from '@/styles';
import {fonts} from '@/styles/typography';

type Props = {
  capitalize?: boolean;
  white?: boolean;
  numberOfLines?: number;
  small?: boolean;
  bold?: boolean;
  boldest?: boolean;
  onPress?: () => void;
  allowFontScaling?: boolean;
  adjustFontSizeToFit?: boolean;
};

type State = {};

const TextBase: TextStyle = {
  ...Typography.M_regular,
  ...Spacing.text,
  color: Colors.normalTextColors.text,
};

const TextStyles = StyleSheet.create({
  baseStyle: {
    ...TextBase,
  },
});

function parseTextWithBold(text: string, style: any) {
  let parts: string[] = [];
  let forceBlack = false; // Si se usa <b> se pondrá en bold y negrita, si es usa <strong> se mantiene el estilo y se ve más fuerte
  let boldest = false;
  if (text.includes('<bb>')) {
    parts = text.split(/<bb>(.*?)<\/bb>/g);
    boldest = true;
  } else if (text.includes('<b>')) {
    parts = text.split(/<b>(.*?)<\/b>/g);
    forceBlack = true;
  } else if (text.includes('<strong>')) {
    parts = text.split(/<strong>(.*?)<\/strong>/g);
  }

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // Si el índice es impar, esto significa que es un texto entre <b> y </b>
      if (boldest) {
        return (
          <Text key={`text_boldest_${index}`} boldest>
            {part}
          </Text>
        );
      }
      if (forceBlack) {
        return (
          <Text key={`text_bold_${index}`} bold>
            {part}
          </Text>
        );
      }
      return (
        <Text key={`text_bold_${index}`} style={[...style, {fontFamily: fonts.poppins.semiBold}]}>
          {part}
        </Text>
      );
    }
    // Si el índice es par, esto significa que es texto normal
    return part;
  });
}

export default class Text extends Component<Props, State> {
  applyStyles(): void {
    this._style = TextStyles.baseStyle;

    if (this.props.small) {
      this._style = [this._style, {...Typography.XS_regular}];
    }

    if (this.props.bold) {
      this._style = [this._style, {...Typography.bold}];
    }

    if (this.props.boldest) {
      this._style = [this._style, {...Typography.boldest}];
    }

    if (this.props.white) {
      this._style = [this._style, {color: Colors.white}];
    }

    this._style = [this._style, this.props.style];
  }

  render(): ReactElement {
    // S'han de tornar a aplicar els estils per si el botó canvia de type
    this.applyStyles();

    let content = this.props.children;
    if (typeof content === 'string' && (content.includes('<b>') || content.includes('<bb>') || content.includes('<strong>'))) {
      content = parseTextWithBold(content, this._style);
    }
    if (typeof content === 'string' && this.props.capitalize) {
      content = content.charAt(0).toUpperCase() + content.slice(1).toLowerCase();
    }
    return (
      <NativeText
        adjustsFontSizeToFit={this.props.adjustFontSizeToFit}
        style={this._style}
        allowFontScaling={this.props.allowFontScaling}
        numberOfLines={this.props.numberOfLines}
        onPress={this.props.onPress}
        suppressHighlighting>
        {content}
      </NativeText>
    );
  }
}
