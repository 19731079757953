import {FC, useEffect} from 'react';
import {isAndroid, isiOS} from '@utils/appUtils';
import {Platform} from 'react-native';
import * as Device from 'expo-device';
import {PropsWithNavigation} from '@/lib/components/Component';

// Tipo any porque no es un tipo reconocido por React-native, es sólo reconocido por el DOM de la web
declare const window: any;

// Typing
type Props = {};

// Component
const StoreScreen: FC<PropsWithNavigation<Props, 'store'>> = props => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      let url = 'https://reciclos.com';
      if (isiOS) url = `itms-apps://itunes.apple.com/app/id1487106432`;
      if (isAndroid) url = 'https://play.google.com/store/apps/details?id=com.ecoembes.reciclos';
      if (Device.osName === 'Windows') url = 'https://reciclos.com';
      window.location.assign(url);
    } else {
      props.navigation.navigate('Home');
    }
  }, []);

  return null;
};

export default StoreScreen;
