import {margin, padding, scaleSize} from './mixins';

export const SCALE_70 = scaleSize(60);
export const SCALE_30 = scaleSize(30);
const SCALE_25 = scaleSize(25);
export const SCALE_20 = scaleSize(20);
export const SCALE_15 = scaleSize(15);
export const SCALE_10 = scaleSize(10);
export const SCALE_5 = scaleSize(5);

export const Margins = {
  loginContainer: {
    ...margin(5, 30),
  },
};

export const defaultContainerPadding = 24;

export const Paddings = {
  loginContainer: {
    ...padding(0, 0, SCALE_20),
  },
  appContiner: {
    ...padding(defaultContainerPadding, defaultContainerPadding),
  },
};

export const link = {
  ...margin(SCALE_25, 0, 0, 0),
};

export const text = {
  ...margin(0, 0, 0, 0),
};

export const picker = {
  ...margin(0, 0, SCALE_25, 0),
  ...padding(0, 0, 0, SCALE_25),
};

export const checkbox = {
  ...margin(0, SCALE_5),
};

export const errorMessage = {
  margin: undefined,
  marginTop: 0,
  marginBottom: 0,
};
