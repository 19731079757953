import {ConfigType} from '@config/config';

/**
 * IMPORTANT for boolean variables, the use of 'toUpperCase' is mandatory, due to typescript compilation process.
 * '${REC_CLIENT_SHOWFULLERROR}' === 'true' will be compiled to: !1 and we will lose the string constant to be replaced
 */

const Configuration: ConfigType = {
  // Server URL
  serverUrl: '${REC_CLIENT_SERVER_URL}',
  statusBlob: '${REC_CLIENT_STATUSBLOB}',
};

// Export configuration
export default Configuration;
