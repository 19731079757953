const Configuration = {
  // Server URL
  serverUrl: 'https://des-api.reciclos.com',

  // Blob for status
  statusBlob: 'https://ecoazne1noprohotrec2.blob.core.windows.net/utils/status-local.json',
};

// Export configuration
export default Configuration;
