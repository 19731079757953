import React, {FC, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Config from '@config/config';
import {LEGAL_TEXT_CATEGORY} from '@reciclos/core';
import axios from 'axios';
import {ScrollMode, Viewer, ViewMode, Worker} from '@react-pdf-viewer/core';
import {Colors} from '@styles';
import * as pdfjs from 'pdfjs-dist';
import {zoomPlugin} from '@react-pdf-viewer/zoom';
import {WINDOW_HEIGHT_WEB_APP} from '@/styles/mixins';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const pdfjsVersion = pdfjs.version;
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

const PrivacyPolicyWebAppScreen: FC<{}> = () => {
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState('');

  useEffect(() => {
    getActivePrivacyPolicy();
  }, []);

  const getActivePrivacyPolicy = () => {
    const url = '/api/v1/legal/texts/logic/getActiveLegalText';
    axios
      .get(`${Config.serverUrl}${url}`, {
        params: {
          textCategory: 'PP' as LEGAL_TEXT_CATEGORY,
          regionId: undefined,
          currentMarket: undefined,
        },
        headers: {
          'api-key': 'TOKEN_EXTRA_SECRET',
        },
      })
      .then(response => {
        if (response) {
          setPrivacyPolicyUrl(response.data.response.legalText.urls.es);
        }
      })
      .catch(error => console.error('error: ', error));
  };

  const zoomPluginInstance = zoomPlugin();
  let ZoomInButton;
  let ZoomOutButton;
  let ZoomPopover;
  if (zoomPluginInstance) {
    ZoomInButton = zoomPluginInstance.ZoomInButton;
    ZoomOutButton = zoomPluginInstance.ZoomOutButton;
    ZoomPopover = zoomPluginInstance.ZoomPopover;
  }

  return (
    <View style={styles.container}>
      <View style={styles.pdfViewerHeader}>
        {ZoomOutButton && ZoomPopover && ZoomInButton && (
          <>
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </>
        )}
      </View>
      <View style={styles.pdfScrollContainer}>
        {privacyPolicyUrl !== '' && (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={privacyPolicyUrl} viewMode={ViewMode.SinglePage} scrollMode={ScrollMode.Vertical} plugins={[zoomPluginInstance]} defaultScale={1.2} />
          </Worker>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: WINDOW_HEIGHT_WEB_APP,
  },
  pdfScrollContainer: {
    flex: 1,
    height: '100%',
    flexDirection: 'column',
  },
  pdfViewerHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
    marginTop: 4,
    backgroundColor: Colors.background,
    zIndex: 1,
  },
});

export default PrivacyPolicyWebAppScreen;
