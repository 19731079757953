import {TextStyle} from 'react-native';
import {scaleFont} from './mixins';
import {black, redDark} from '@/styles/colors';

// FONTS FAMILY
export const fonts = {
  poppins: {
    regular: 'poppins-regular',
    medium: 'poppins-medium',
    semiBold: 'poppins-semi-bold',
    bold: 'poppins-bold',
  },
};

// FONTS SIZE
const FONT_SIZE_24 = scaleFont(24);
const FONT_SIZE_22 = scaleFont(22);
const FONT_SIZE_20 = scaleFont(20);
const FONT_SIZE_16 = scaleFont(16);
const FONT_SIZE_14 = scaleFont(14);
const FONT_SIZE_12 = scaleFont(12);

/** *********
 * Size Defaults
 ********* */

const XXL_default = {
  fontSize: FONT_SIZE_24,
  lineHeight: 32,
  letterSpacing: 0,
};

const XL_default = {
  fontSize: FONT_SIZE_22,
  lineHeight: 32,
  letterSpacing: 0,
};

const L_default = {
  fontSize: FONT_SIZE_20,
  lineHeight: 24,
  letterSpacing: 0,
};

const M_default = {
  fontSize: FONT_SIZE_16,
  lineHeight: 24,
  letterSpacing: 0,
};

const S_default = {
  fontSize: FONT_SIZE_14,
  lineHeight: 24,
  letterSpacing: 0,
};

const XS_default = {
  fontSize: FONT_SIZE_12,
  lineHeight: 16,
  letterSpacing: 0,
};

/** *********
 * Display
 ********* */

// SIZE - XXL
export const XXL_semibold: TextStyle = {
  ...XXL_default,
  fontFamily: fonts.poppins.semiBold,
  color: black,
};

export const XXL_bold: TextStyle = {
  ...XXL_default,
  fontFamily: fonts.poppins.bold,
  color: black,
};

// SIZE - XL
export const XL_semibold: TextStyle = {
  ...XL_default,
  fontFamily: fonts.poppins.semiBold,
  color: black,
};

export const XL_bold: TextStyle = {
  ...XL_default,
  fontFamily: fonts.poppins.bold,
  color: black,
};

// SIZE - L
export const L_semibold: TextStyle = {
  ...L_default,
  fontFamily: fonts.poppins.semiBold,
  color: black,
};

export const L_bold: TextStyle = {
  ...L_default,
  fontFamily: fonts.poppins.bold,
  color: black,
};

/** *********
 * Text
 ********* */

// SIZE - M
export const M_regular: TextStyle = {
  ...M_default,
  fontFamily: fonts.poppins.regular,
  color: black,
};

export const M_medium: TextStyle = {
  ...M_default,
  fontFamily: fonts.poppins.medium,
  color: black,
};

export const M_semibold: TextStyle = {
  ...M_default,
  fontFamily: fonts.poppins.semiBold,
  color: black,
};

export const M_bold: TextStyle = {
  ...M_default,
  fontFamily: fonts.poppins.bold,
  color: black,
};

// SIZE - S
export const S_regular: TextStyle = {
  ...S_default,
  fontFamily: fonts.poppins.regular,
  color: black,
};

export const S_medium: TextStyle = {
  ...S_default,
  fontFamily: fonts.poppins.medium,
  color: black,
};

export const S_semibold: TextStyle = {
  ...S_default,
  fontFamily: fonts.poppins.semiBold,
  color: black,
};

export const S_bold: TextStyle = {
  ...S_default,
  fontFamily: fonts.poppins.bold,
  color: black,
};

// SIZE - XS
export const XS_regular: TextStyle = {
  ...XS_default,
  fontFamily: fonts.poppins.regular,
  color: black,
};

export const XS_medium: TextStyle = {
  ...XS_default,
  fontFamily: fonts.poppins.medium,
  color: black,
};

export const XS_semibold: TextStyle = {
  ...XS_default,
  fontFamily: fonts.poppins.semiBold,
  color: black,
};

export const XS_bold: TextStyle = {
  ...XS_default,
  fontFamily: fonts.poppins.bold,
  color: black,
};

// SPECIAL FONTS
export const underline: TextStyle = {
  textDecorationLine: 'underline',
  textDecorationStyle: 'solid',
};

export const bold: TextStyle = {
  fontFamily: fonts.poppins.semiBold,
};

export const boldest: TextStyle = {
  fontFamily: fonts.poppins.bold,
};

export const errorMessage: TextStyle = {
  ...M_regular,
  color: redDark,
};
