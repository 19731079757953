import {I18N_ACTIONS} from '@store/actionTypes';
import {I18N_ACTIONS_TYPES} from '@reducers/i18n/actions';
import {TranslateFunctionInterface} from '@/types';

// Type definitions
export type I18NState = {
  readonly t: TranslateFunctionInterface;
};

// Initial state
const initialState: I18NState = {
  t: () => '',
};

// Actions that modify the state
export default (stateIn: I18NState, action: I18N_ACTIONS_TYPES): I18NState => {
  const state = stateIn || initialState;

  if (action.type === I18N_ACTIONS.SET_TRANSLATION_FUNCTION) {
    return {
      ...state,
      t: action.t,
    };
  }
  return state;
};
