import {Platform} from 'react-native';
import Constants, {AppOwnership, ExecutionEnvironment} from 'expo-constants';
import * as Device from 'expo-device';

// Saber si es Webapp o app nativa

export const isApp = Constants.appOwnership === null;
export const isWeb = Platform.OS === 'web';
export const isAndroidApp = Platform.OS === 'android' && isApp;
export const isiOSApp = Platform.OS === 'ios' && isApp;
// Saber el SO del dispositivo
export const isiOS = Device.osName === 'iOS' || Device.osName === 'iPadOS' || Device.osName === 'Mac OS';
export const isAndroid = !isiOS;
export const isExpo = Constants.appOwnership === AppOwnership.Expo;
export const deviceManufacturer = Device.manufacturer ? Device.manufacturer : 'unknown';
export const deviceName = Device.modelName ? Device.modelName : 'unknown';
export const isExpoGo = Constants.executionEnvironment === ExecutionEnvironment.StoreClient;
export const deviceYearClass = Device.deviceYearClass || 0;

export const getPropertiesModified = (oldObject: any, newObject: any) => {
  const modifiedProperties = [];

  for (const property in newObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (oldObject.hasOwnProperty(property)) {
      if (oldObject[property] !== newObject[property]) {
        console.debug(oldObject[property]);
        console.debug(newObject[property]);
        modifiedProperties.push(property);
      }
    }
  }

  return modifiedProperties;
};
