/** *******
 * Paleta de Colores
 ******** */
const primaryColors = {
  primaryLight: '#FFF6D8',
  primary: '#F9D706',
  primaryDark: '#E3C403',
  primaryDeep: '#533F03',
};

export const {primary, primaryLight, primaryDark, primaryDeep} = primaryColors;

const baseColors = {
  black: '#141101',
  white: '#FFFFFF',
};

export const {black, white} = baseColors;

const alertColors = {
  redDark: '#9F0406',
  redDefault: '#FB7172',
  redLight: '#FBEFEF',
  greenDark: '#145C3A',
  greenDefault: '#5CDB9E',
  greenLight: '#EFFBF5',
};

export const {redDark, redDefault, redLight, greenDefault, greenLight, greenDark} = alertColors;

const grayColors = {
  gray025: '#F4F4F4',
  gray050: '#DFDFDF',
  gray100: '#CACACA',
  gray200: '#B5B5B4',
  gray300: '#A0A09F',
  gray400: '#8B8B8A',
  gray500: '#767675',
  gray600: '#616160',
  gray700: '#4B4C4B',
  gray800: '#363636',
  gray900: '#212121',
};

export const {gray025, gray050, gray100, gray200, gray300, gray400, gray500, gray600, gray700, gray800, gray900} = grayColors;

const waste_containers = {
  blue: '#DCE7F2',
  gray025,
  gray050,
  orange: '#FFDEC5',
  yellow: '#FFF2C9',
  green: '#E1FFCE',
};

const infoGroupColors = [primaryDeep, '#846E15', '#987F12', '#AB9110', '#BFA20D', '#D2B40B', '#E6C508', primary];

/** *******
 * Fin Paleta de Colores
 ******** */

// ACTIONS
const disabled = gray100;
const warning = redDefault;

const background = gray025;
const backgroundLogin = primary;

const graySeparator = gray100;

const normalTextColors = {
  H1: black,
  H2: black,
  H3: black,
  H5: black,
  text: black,
  link: black,
  textInput: black,
  checkBox: black,
};

const button = {
  background: primaryDeep,
  text: primary,
  disabled: gray025,
  disabledBorder: gray100,
  disabledText: gray100,
  primaryText: white,
  borderColor: primaryDeep,
};

const tabBar = {
  background: white,
  borderColor: gray025,
  textActive: black,
  textInactive: gray500,
};

const badge = {
  background: primaryLight,
  text: white,
};

const imageBadge = {
  status: {
    active: primary,
    finished: primaryLight,
    pending: primaryLight,
  },
};

const datePicker = {
  calendarHeader: black,
};

const messageBox = {
  background: primaryLight,
  leftBorder: primary,
};

const fakeSmsBanner = {
  background: primary,
};

export {
  warning,
  background,
  backgroundLogin,
  normalTextColors,
  button,
  tabBar,
  badge,
  imageBadge,
  disabled,
  graySeparator,
  infoGroupColors,
  datePicker,
  messageBox,
  waste_containers,
  fakeSmsBanner,
};
