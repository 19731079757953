import Constants from 'expo-constants';
import BaseConfiguration from './config.base';

// Load dynamic config by release channel
let LocalConfiguration;
try {
  if (Constants && Constants.expoConfig && Constants.expoConfig.extra && Constants.expoConfig.extra.env) {
    switch (Constants.expoConfig.extra.env) {
      case 'local':
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        LocalConfiguration = require('./config.local').default;
        break;
      case 'template':
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        LocalConfiguration = require('./config.template').default;
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        LocalConfiguration = require('./config.local').default;
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    LocalConfiguration = require('./config.local').default;
  }
} catch (e) {
  LocalConfiguration = {};
}

const Config: typeof BaseConfiguration = {
  ...BaseConfiguration,
  ...LocalConfiguration,
};

export type ConfigType = typeof BaseConfiguration;

export default Config;
