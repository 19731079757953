// eslint-disable-next-line max-classes-per-file
import React, {PropsWithChildren, RefObject} from 'react';
import {StyleProp} from 'react-native';
import PropTypes from 'prop-types';
import {CompositeNavigationProp, EventListenerCallback, EventMapBase, EventMapCore, NavigationState, RouteProp} from '@react-navigation/native';
import {RouteParamTypes} from '@scenes/RouteParamsTypes';
import {TranslateFunctionInterface} from '@/types';

// Typing
export type PropsWithNavigation<P, R extends keyof RouteParamTypes = 'emptyRouteParams'> = {
  route: RouteProp<Partial<RouteParamTypes>, R>;
  navigation: CompositeNavigationProp<any, any>;
} & PropsWithChildren<P>;

export type ScreenCommonProps<R extends keyof RouteParamTypes = 'emptyRouteParams'> = {
  style?: StyleProp<any>;
  route: RouteProp<Partial<RouteParamTypes>, R>;
  // navigation: CompositeNavigationProp<any, any> & {getParam: never}; // Use this definition for searching the error .getParam(
  navigation: CompositeNavigationProp<any, any>;
  ref?: RefObject<any>;
  children?: any;
};

export type CommonProps<R extends keyof RouteParamTypes> = Partial<ScreenCommonProps<R>>;

export type ContextType = {t: TranslateFunctionInterface};

export type WatchOptionsType = {
  truthy?: boolean;
};

abstract class ComponentBase<P extends CommonProps<any> = {}, S = {}> extends React.Component<P, S> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  context: ContextType;
  _style: any;
  watches: {[key in keyof P]?: {callback: (newValue: any, oldValue: any) => void; value: any; options: WatchOptionsType}} = {};
  listeners: (() => void)[];

  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props: P) {
    super(props);
    this.applyStyles();
    this.listeners = [];
  }

  componentDidUpdate(nextProps: any, prevState: any) {
    this.applyStyles();
    this.runWatches();
  }

  componentWillUnmount() {
    for (const removeListener of this.listeners) {
      removeListener();
    }
  }

  getFakeContext() {
    return {t: this.context.t};
  }

  runWatches() {
    for (const fieldStr of Object.keys(this.watches)) {
      const field: keyof P = fieldStr as keyof P;
      const newValue = this.props[field];
      const oldValue = this.watches[field]?.value;
      const watchDef = this.watches[field]!;
      if (oldValue !== newValue) {
        watchDef.value = newValue;
        if (!watchDef.options.truthy || (watchDef.options.truthy && newValue)) {
          watchDef.callback(newValue, oldValue);
        }
      }
    }
  }

  addListener(eventName: Extract<keyof EventMapCore<NavigationState>, string>, callback: EventListenerCallback<EventMapBase, keyof EventMapBase>) {
    if (!this.props.navigation) {
      console.error("For using 'addListener', the component has to be a ScreenComponent");
      return;
    }
    this.listeners.push(this.props.navigation.addListener(eventName, callback));
  }

  watch<T extends keyof P>(field: T, callback: (newValue: P[T], oldValue: any) => void | Promise<void>, options: WatchOptionsType = {}) {
    this.watches[field] = {value: undefined, callback, options};
    this.runWatches();
  }

  applyStyles(): void {
    // This is intentional
  }
}

// Component
export default abstract class Component<P = {}, S = {}, R extends keyof RouteParamTypes = 'emptyRouteParams'> extends ComponentBase<P & CommonProps<R>, S> {}

// Screen Component
export abstract class ScreenComponent<P = {}, S = {}, R extends keyof RouteParamTypes = 'emptyRouteParams'> extends ComponentBase<P & ScreenCommonProps<R>, S> {}
