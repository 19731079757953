import React, {FC, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Linking, ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native';
import {isiOS} from '@utils/appUtils';
import {getResource, Images} from '@utils/imagesUtils';
import {useFonts} from 'expo-font';
import Text from '@/components/atoms/Text';
import {ContextType} from '@/lib/components/Component';
import {Colors, Typography} from '@/styles';
import {WINDOW_HEIGHT_WEB_APP, WINDOW_WIDTH_WEB_APP} from '@/styles/mixins';
import ImageExpo from '@/components/atoms/ImageExpo';
import {ClientConstants} from '@/config/clientConstants';

const buttonWidth = (WINDOW_WIDTH_WEB_APP - 80) / 2;

const DiscontinuedPWAScreen: FC<{}> = (props, context: ContextType) => {
  const [fontsLoaded] = useFonts({
    'Poppins-Medium': require('../assets/fonts/Poppins-Medium.ttf'),
    'Poppins-Regular': require('../assets/fonts/Poppins-Regular.ttf'),
    'Poppins-Semi-Bold': require('../assets/fonts/Poppins-SemiBold.ttf'),
    'Poppins-Bold': require('../assets/fonts/Poppins-Bold.ttf'),
  });

  useEffect(() => {
    const goToAppTimeout = setTimeout(() => {
      openApp();
    }, 30000);
    return () => {
      clearTimeout(goToAppTimeout);
    };
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  const openAndroidApp = async (): Promise<void> => {
    await Linking.openURL(`https://play.google.com/store/apps/details?id=${ClientConstants.androidAppId}`);
  };

  const openIOSApp = async (): Promise<void> => {
    if (isiOS) {
      await Linking.openURL(`itms-apps://itunes.apple.com/app/id${ClientConstants.iosAppId}`);
    } else {
      await Linking.openURL(`https://itunes.apple.com/app/id${ClientConstants.iosAppId}`);
    }
  };

  const openApp = (): void => {
    if (isiOS) {
      openIOSApp();
    } else {
      openAndroidApp();
    }
  };

  return (
    <ScrollView style={{flex: 1}} contentContainerStyle={{height: WINDOW_HEIGHT_WEB_APP}}>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.headerContent}>
            <Text style={styles.headerText}>{context.t('👋 Adiós, web.')}</Text>
            <Text style={styles.headerText}>{context.t('😎 Hola, app.')}</Text>
          </View>
          <ImageExpo source={getResource(Images.discontinued_pwa)} width={WINDOW_WIDTH_WEB_APP} style={{height: 350}} />
        </View>
        <View style={styles.body}>
          <View style={{maxHeight: '100%'}}>
            <View style={{paddingHorizontal: 20}}>
              <View style={styles.bodyContent}>
                <Text style={styles.bodyText}>{context.t('RECICLOS ya no está disponible en formato web.')}</Text>
                <Text style={styles.bodyText}>
                  {context.t('Por favor, {bold} desde Google Play Store o App Store y sigue ayudando al medio ambiente con RECICLOS.', {
                    bold: (
                      <Text key="bold" bold>
                        {context.t('instala la App')}
                      </Text>
                    ),
                  })}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.buttonsWrap}>
          <View style={styles.button}>
            <TouchableOpacity
              onPress={async () => {
                await openAndroidApp();
              }}>
              <ImageExpo source={getResource(Images.google_play_large)} width={buttonWidth} resizeMode="contain" />
            </TouchableOpacity>
          </View>
          <View style={styles.button}>
            <TouchableOpacity
              onPress={async () => {
                await openIOSApp();
              }}>
              <ImageExpo source={getResource(Images.app_store_large)} width={buttonWidth} resizeMode="contain" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};
DiscontinuedPWAScreen.contextTypes = {
  t: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: WINDOW_HEIGHT_WEB_APP,
  },
  header: {
    backgroundColor: Colors.white,
  },
  headerContent: {
    padding: 35,
    paddingBottom: 15,
  },
  headerText: {
    ...Typography.XL_bold,
    fontSize: 40,
    lineHeight: 50,
  },
  titleStyle: {
    ...Typography.XL_bold,
    color: Colors.normalTextColors.H1,
    fontWeight: '600',
    letterSpacing: 0.2,
    paddingHorizontal: 0,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  body: {
    flexShrink: 0,
    flexGrow: 1,
    backgroundColor: Colors.primary,
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  bodyContent: {},
  bodyText: {
    ...Typography.M_regular,
    paddingBottom: 16,
  },
  buttonsWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
    paddingBottom: 40,
    backgroundColor: Colors.primary,
  },
  button: {
    borderRadius: buttonWidth / 20,
    overflow: 'hidden',
  },
});

export default DiscontinuedPWAScreen;
