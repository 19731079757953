import React, {CSSProperties} from 'react';
import {Component} from '@lib_components';
import {Dimensions} from 'react-native';
import {isWeb} from '@utils/appUtils';
import {WINDOW_HEIGHT_WEB_APP, WINDOW_WIDTH_WEB_APP} from '@/styles/mixins';

const IPhoneOverlayStyle: CSSProperties = {
  position: 'absolute',
  margin: 'auto',
  maxHeight: WINDOW_HEIGHT_WEB_APP,
  maxWidth: WINDOW_WIDTH_WEB_APP,
  height: '100%',
  width: WINDOW_WIDTH_WEB_APP,
  borderRadius: 40,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  boxShadow: '0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111',
  pointerEvents: 'none',
};

type Props = {};

export default class IPhoneOverlay extends Component<Props> {
  render() {
    const {width} = Dimensions.get('window');
    if (!isWeb || width <= 500) return null;
    return (
      <div style={IPhoneOverlayStyle}>
        <style>{'#root {background: -webkit-linear-gradient(top, rgb(206, 165, 0) 21%, rgb(252, 235, 7) 72%); }'}</style>
      </div>
    );
  }
}
