// eslint-disable-next-line import-alias/import-alias
import pkg from '../../package.json';

export const ScanditLibLocation = 'https://cdn.jsdelivr.net/npm/scandit-sdk@4.x/build';
export const CoreVersion = pkg.dependencies['@reciclos/core'];
export const ClientVersion = 85;

export enum MODAL_NAMES {
  DoorToDoorLimites = 'DoorToDoorLimites',
  QrContainerLimites = 'QrContainerLimites',
  SmartBinLimites = 'SmartBinLimites',
  AvantContainerLimites = 'AvantContainerLimites',
}

export const ClientConstants = {
  // ID of the apps
  iosAppId: '1487106432',
  androidAppId: 'com.ecoembes.reciclos',
};

export const MIN_LOCATION_ACCURACY = 15;
export const WAIT_TIME_BETWEEN_BARCODES_SCAN = 2000;
