import React from 'react';
import {registerRootComponent} from 'expo';
import {AppRegistry, Platform, View} from 'react-native';
import {DEFAULT_LANGUAGE} from '@reciclos/core';
import {translations} from '@locales/translations';
import I18n, {i18nState} from 'redux-i18n';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {isExpo} from './src/utils/appUtils';
import IPhoneOverlay from './src/components/atoms/IPhoneOverlay';
import {WINDOW_HEIGHT_WEB_APP, WINDOW_WIDTH_WEB_APP} from './src/styles/mixins';
import {Colors} from './src/styles';
import I18NReducer from './src/reducers/i18n/reducer';
import App from './App';
import ReciclosNavigatorWeb from './src/navigations/ReciclosNavigator';

// WebApp
if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(combineReducers({i18n: I18NReducer, i18nState}), composeEnhancers(applyMiddleware(thunk)));

  // TODO pot ser que falti això per a que funcionin les traduccions???
  // this.props.setTranslationFunction(this.context.t);

  const Web = () => (
    <>
      <Provider store={store}>
        <I18n translations={translations} initialLang={DEFAULT_LANGUAGE}>
          <IPhoneOverlay />
          <View
            style={{
              width: WINDOW_WIDTH_WEB_APP,
              maxWidth: WINDOW_WIDTH_WEB_APP,
              height: '100%',
              maxHeight: WINDOW_HEIGHT_WEB_APP,
              margin: 'auto',
              backgroundColor: Colors.white,
            }}>
            <ReciclosNavigatorWeb />
          </View>
        </I18n>
      </Provider>
    </>
  );
  registerRootComponent(Web);
} else {
  // App
  if (!isExpo) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const {ARView} = require('./src/lib/scandit/mobile/ARView');
    AppRegistry.registerComponent(ARView.moduleName, () => ARView);
  }

  AppRegistry.registerComponent('main', () => App);
}
